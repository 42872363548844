import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryBoardsArgs } from '../../generated/types';

export const GET_BOARDS = gql`
  query Boards($where: BoardsInputWhere!, $sort: InputSort!, $limit: Int) {
    boards(where: $where, sort: $sort, limit: $limit) {
      uuid

      title
      description
      linkedin
      availableDate
      location
      locationStreet
      locationPostCode
      locationCity
      locationCountry
      locationUrl
      locationLatitude
      locationLongitude
      educationLevel
      interestLevel
      seniorityLevel
      workRightEU
      workRightUK
      industry
      # coreSkills
      # suggestedCoreSkills

      # files {
      #   uuid
      #   filename
      #   size
      #   mimetype
      #   formattedMimetype
      #   resource
      #   createdAt
      #   updatedAt
      #   isDeleted
      #   temporaryUrl
      #   aiCoreSkills
      #   aiSoftSkills
      #   aiJobTitle
      #   aiIsValidResume
      #   isProcessed
      #   temporaryImageUrl
      # }

      # followers {
      #   uuid
      #   email
      #   nickname
      #   name
      #   avatar
      # }

      isOwner
      owner {
        uuid
        email
        nickname
        name
      }

      createdAt
      createdBy {
        uuid
        email
        nickname
        name
      }
      updatedAt
      updatedBy {
        uuid
        email
        nickname
        name
      }

      # permissions
      # isPublic
      # publicUrl

      isNew
      isDeleted
    }
  }
`;

export const useGetBoards = (options?: QueryHookOptions<Pick<Query, 'boards'>, QueryBoardsArgs>) =>
  useQuery<Pick<Query, 'boards'>, QueryBoardsArgs>(GET_BOARDS, options);

export const useLazyGetBoards = (options?: QueryHookOptions<Pick<Query, 'boards'>, QueryBoardsArgs>) =>
  useLazyQuery<Pick<Query, 'boards'>, QueryBoardsArgs>(GET_BOARDS, options);
